<app-header *ngIf="!monopunto"></app-header>

<div *ngIf="!monopunto">
  <div class="pageContent">
    <mat-card class="cardsContents">

      <div *ngIf="SpinnerDescargaContrato" style="position: absolute;width: 100%;height: 100%;background-color: #f5f5f56e;display: flex;justify-content: center;align-items: center; flex-direction: column; gap: 20px;" >
        <b>Descargando contrato...</b>
        <mat-spinner></mat-spinner>
      </div>
      
      <h3><b>Tus contratos</b></h3>
      
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>

      <table class="tablaContratos" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

        <!-- Entorno -->
        <ng-container matColumnDef="Entorno">
          <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
            <span mat-sort-header style="font-weight: bolder;"> {{'Entorno' | translate}}</span>
            <input class="filter-input" style="border-bottom: 1px solid #b2b2b2;padding: 6px 2px;" matInput [formControl]="EntornoFilter"
              placeholder="{{'Filtrar' | translate}}" />
          </th>
          <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            <span class="far fa-lightbulb fa-lg padding" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
              matTooltip="Luz"></span>
            <span class="far fa-fire fa-lg padding" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
              matTooltip="Gas"></span>
          </td>
        </ng-container>

        <!-- Contrato -->
        <ng-container matColumnDef="CodigoContrato" *ngIf="!agrupacontratoscups">
          <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
            <span mat-sort-header style="font-weight: bolder;">{{'Contrato' | translate}}</span>
            <input class="filter-input" style="border-bottom: 1px solid #b2b2b2;padding: 6px 2px;" matInput [formControl]="ContratoFilter"
              placeholder="{{'Filtrar' | translate}}" />
          </th>
          <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.CodigoContrato}}

            <span class="far fa-lightbulb fa-lg entornoMovil" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
            matTooltip="Luz"></span>
            <span class="far fa-fire fa-lg entornoMovil" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
              matTooltip="Gas"></span>
          </td>
        </ng-container>

        <!-- Situación -->
        <ng-container matColumnDef="ContratoSituacion">
          <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
            <span mat-sort-header style="font-weight: bolder;">{{'Situación' | translate}}</span>
            <input class="filter-input" style="border-bottom: 1px solid #b2b2b2;padding: 6px 2px;" matInput [formControl]="SituacionFilter"
              placeholder="{{'Filtrar' | translate}}" />
          </th>
          <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            {{element.ContratoSituacion}}
          </td>
        </ng-container>

        <!-- Tarifa -->
        <ng-container matColumnDef="Tarifa">
          <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">
            <span mat-sort-header style="font-weight: bolder;">{{'Tarifa' | translate}}</span>
            <input class="filter-input" style="border-bottom: 1px solid #b2b2b2;padding: 6px 2px;" matInput [formControl]="TarifaFilter" placeholder="{{'Filtrar' | translate}}" />
          </th>
          <td mat-cell *matCellDef="let element" class="tablaContratosBody">
            <!-- <p>Tarifa</p> -->
            {{element.Tarifa}}
          </td>
        </ng-container>

        <!-- Boton Facturas Admin Fincas -->
        <ng-container matColumnDef="Facturas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="tablaContratosCabecera">{{'Descargas' | translate}} </th>
          <td mat-cell *matCellDef="let element" class="tablaContratosBody">
              <img src="assets/img/iconoDescargaFolder.png" width="30px" (click)="descargarContrato(element)" style="display:none !important;" matTooltip="Descargar contratoo" [disabled]="SpinnerDescargaContrato"> 
          </td>
        </ng-container>

        <!-- Desplegable -->
        <ng-container matColumnDef="Dropdown">
          <th mat-header-cell *matHeaderCellDef class="tablaContratosCabecera">Ver más</th>
          <td mat-cell *matCellDef="let element" style="width: 70px; text-align: center; padding: 0 !important;" class="tablaContratosBody">
            <h2 id="desplegableRow" (click)="expandedElement = expandedElement === element ? null : element" matTooltip="Informacón del contrato">
              <i class="fal fa-angle-up"  *ngIf="element == expandedElement"></i>
              <i class="fal fa-angle-down" *ngIf="element != expandedElement"></i>
            </h2>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="contratosDropdown">
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <mat-card class="matCardContratos">&nbsp;
                
                <div id="tablaInfoContrato">
                  <table id="halfTable1">
                    <tr>
                      <td>Fecha Alta</td>
                      <td><b>{{element.FechaAlta |date:'dd/MM/yyyy' }}</b></td>
                    </tr>
                    <tr>
                      <td>Fecha fin de contrato</td>
                      <td><b>{{element.FechaVto |date:'dd/MM/yyyy' }}</b></td>
                    </tr>
                    <tr *ngIf="element.IsPrecarga">
                      <td>Perdido de recarga</td>
                      <td><b>{{element.Periodicidad}}</b></td>
                    </tr>
                    <tr *ngIf="element.IsPrecarga">
                      <td>Recarga</td>
                      <td><b>{{element.RecargaActual}}€</b></td>
                    </tr>
                    <!-- <tr>
                      <td>Recarga mensual invierno/verano</td>
                      <td><b>Invierno</b></td>
                    </tr> -->
                  </table>

                  <table id="halfTable1">
                    <tr>
                      <td>Cups</td>
                      <td><b>{{element.Cups}}</b></td>
                    </tr>
                    <tr>
                      <td>Potencia Contratada</td>
                      <td>
                        <b *ngIf="element.P1">P1: {{element.P1}}&nbsp;</b>
                        <b *ngIf="element.P2">P2: {{element.P2}}&nbsp;</b>
                        <b *ngIf="element.P3">P3: {{element.P3}}&nbsp;</b>
                        <b *ngIf="element.P4">P4: {{element.P4}}&nbsp;</b>
                        <b *ngIf="element.P5">P5: {{element.P5}}&nbsp;</b>
                        <b *ngIf="element.P6">P6: {{element.P6}}&nbsp;</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Tarifa acceso</td>
                      <td><b>{{element.Tarifa}}</b></td>
                    </tr>
                    <tr>
                      <td>Distribuidora</td>
                      <td><b>{{element.NombreDistribuidora}}</b></td>
                    </tr>
                  </table>
                </div>

                <br>
                  <hr style="margin: 0 20px;">
                <br>

                <h3><b>Acciones sociales</b></h3>

                <table>
                  <tr>
                    <td>Adopta un arbol</td>
                    <td>
                      <mat-radio-group [(ngModel)]="element.IsTreedom" disabled>
                        <mat-radio-button [value]="true">Sí</mat-radio-button> &nbsp;
                        <mat-radio-button [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                    </td>
                    <td><b>{{element.ImporteTreedom}}€/Al día</b></td>
                  </tr>
                  <tr>
                    <td>Recogida de plástico</td>
                    <td>
                      <mat-radio-group [(ngModel)]="element.IsPlasticBank" disabled>
                        <mat-radio-button [value]="true">Sí</mat-radio-button> &nbsp;
                        <mat-radio-button [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                    </td>
                    <td><b>{{element.ImportePlasticBank}}€/Al día</b></td>
                  </tr>
                </table>

                <br>
                  <hr style="margin: 0 20px;">
                <br>

                <h3><b>Posibles descuentos</b></h3>

                <table>
                  <tr>
                    <td>Tarifa control</td>
                    <td><b>{{element.DescuentoTarifa || 0}}% Sobre el precio bruto</b></td>
                  </tr>
                  <tr>
                    <td>Acierto</td>
                    <td><b>{{element.DescuentoOnline || 0}}% Sobre el precio bruto</b></td>
                  </tr>
                  <tr>
                    <td>100% Digital</td>
                    <td><b>{{element.DescuentoAcierta || 0}}% Sobre el precio bruto</b></td>
                  </tr>
                </table>

                <br>
                  <hr style="margin: 0 20px;">
                <br>

                <h3><b>Informacion personal</b></h3>

                <table>
                  <tr>
                    <td>Titular del contrato</td>
                    <td><b>{{element.NombreCliente}} {{element.PrimerApellido}} {{element.SegundoApellido}}</b></td>
                  </tr>
                  <tr>
                    <td>DNI</td>
                    <td><b>{{element.Identidad}}</b></td>
                  </tr>
                  <tr>
                    <td>Dirección</td>
                    <td><b>{{element.DireccionSuministro}}</b></td>
                  </tr>
                </table>

                <br>
                  <hr style="margin: 0 20px;">
                <br>

                <h3><b>Datos de pago</b></h3>

                <h4>Datos de facturación</h4>

                <table>
                  <tr>
                    <td>Nombre</td>
                    <td><b>{{element.NombrePagador}}</b></td>
                  </tr>
                  <tr>
                    <td>DNI</td>
                    <td><b>{{element.Identidad}}</b></td>
                  </tr>
                  <tr>
                    <td>Dirección</td>
                    <td><b>{{element.DirecionFact}}</b></td>
                  <tr>
                    <td>CNAE</td>
                    <td><b>{{element.CNAE}}</b></td>
                  </tr>
                </table>

                <br>
                  <hr style="margin: 0 20px;">
                <br>

                <h4>Datos Bancarios</h4>

                <table>
                  <tr>
                    <td>Titular</td>
                    <td><b>{{element.NombreCliente}}</b></td>
                  </tr>
                  <tr>
                    <td>Número de cuenta</td>
                    <td><b>{{element.IBAN}}</b></td>
                  </tr>
                </table>

                <br>

                <div class="botonAltaContrato" matTooltip="Ver facturas">
                  <button (click)="verFacturasContrato(element)" style="padding: 3px 12px;" [disabled]="element.NumFacturas == 0">
                    <span> Ver {{element.NumFacturas}} facturas</span>
                    <span><img src="assets/img/iconoVerFacturasContrato.png" width="25px"></span>
                    </button>
                </div>
              </mat-card>

              <!-- <div id="verFacturas">
                <button mat-raised-button (click)="verFacturasContrato(element)">Ver <b>{{element.NumFacturas}}</b> facturas <i class="fal fa-file-spreadsheet"></i></button>
              </div> -->

              
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="cargarDescuentos(element)" class="example-element-row" [class.example-expanded-row]="expandedElement === element"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

      </table>
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>


      <!-- <button  matTooltip="Descargar facturas" mat-button color="primary"
          [disabled]="this.dataSource.data.length < 1" class="mt-20 primary" [ngClass]="primary"
          (click)="descargaMasivaFacturasDialog()">
          <i class="far fa-file-archive"></i> {{'Descarga facturas' | translate}}
        </button> -->

        
        <div class="botonAltaContrato" matTooltip="Dar de alta un nuevo contrato">
          <a href="{{nuevoContratoEntorno}}" target="_blank" class="nav-link">
            <button>
              <span>Nuevo contrato</span>
              <span id="iconPlus"><i class="fas fa-plus"></i></span>
            </button>
          </a>
        </div>
    </mat-card>
  </div>
</div>

<!-- <app-footer *ngIf="!monopunto"></app-footer> -->

<!-- <app-perfil-contrato [codigo]="contratos[0].IdContrato" *ngIf="monopunto"></app-perfil-contrato> -->